<template>
  <div class="container">
    <Header v-if="isElectron" />
    <div class="cpt-index">
      <div class="login-left">
        <span class="title">智能空域</span>
        <span class="sub-title">指挥控制系统</span>
        <img src="~@/assets/loginImage/login_left_img.png" />
      </div>
      <div class="login-box">
        <div class="login_form_head">
          <img src="~@/assets/loginImage/login-login-from-logo.png" class="login-logo" />
          <span class="title">欢迎登录</span>
          <span class="sub-title">智能空域指挥控制系统</span>
        </div>
        <div class="form-box" :class="{}">
          <el-form ref="login_form" class="login-form" :model="loginForm" :rules="rules" label-width="0px"
            label-position="left" @submit.native.prevent="handleLogin">
            <el-card v-if="isElectron" shadow="always" class="dog-card">
              {{
                isDog
                ? "已识别登录密钥，点击登录进入系统"
                : "未检测到密钥，请插入加密狗！"
              }}
            </el-card>
            <template v-if="loginMode === 'account_password'">
              <!-- 用户名 -->
              <el-form-item v-if="!isElectron" prop="username" class="login-username">
                <span class="form-lable">用户名</span>
                <el-input v-model.trim="loginForm.username" placeholder="请输入用户名">
                </el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item v-if="!isElectron" prop="password" class="login-password">
                <span class="form-lable">密码</span>
                <!-- <el-input name="passwordHide"
                        type="password"
                    style="display: none"></el-input>-->
                <el-input v-model.trim="loginForm.password" show-password type="password" placeholder="请输入密码"
                  autocomplete="off">
                </el-input>
              </el-form-item>
              <!-- 下拉选择场景 -->
              <el-form-item>
                <span class="form-lable">场景</span>
                <el-select v-model="sceneValue" placeholder="请选择" @change="sceneChange" popper-class="scene-select">
                  <el-option v-for="item in sceneOption" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 文本 -->
              <div class="login-tag" :class="{
                [`tag-color-true`]: tag_state,
                [`tag-color-false`]: !tag_state,
              }">
                {{ tooptip }}
              </div>
            </template>
            <div class="login-check">
              <el-checkbox v-model="checked" text-color="#badfee"></el-checkbox>
              <span>我已阅读并同意</span>
              <span @click="pageState = 1" class="xieyi">《用户协议》</span>
              <span>和</span>
              <span @click="pageState = 2" class="xieyi">《隐私保护》</span>
            </div>
            <!-- 登录 -->
            <el-button v-if="loginMode === 'account_password'" class="login_button" type="primary" native-type="submit"
              :loading="loading">登 录</el-button>
            <span class="version">版本：{{ systemVersion }}</span>
          </el-form>
        </div>
      </div>
      <UserAgreement :page-state="pageState" @changeState="changeState" />
      <PrivacyAgreement :page-state="pageState" @changeState="changeState" />
    </div>
  </div>
</template>

<script>
import { LoginAPI } from "@/api";
import { mapActions } from "vuex";
import Vue from 'vue'
import axios from "axios";
import UserAgreement from "./components/user.vue";
import PrivacyAgreement from "./components/privacy.vue";
import Header from "@/components/header";
const electron = window.electron;
const ipcRenderer = electron?.ipcRenderer;
export default {
  name: "Login",
  components: {
    Header,
    UserAgreement,
    PrivacyAgreement,
  },
  data () {
    const validatePassword = (rule, value, callback) => {
      if (!value || !value.trim()) {
        if (this.loginForm.username) {
          this.tooptip = "请输入密码";
          this.tag_state = true;
        } else {
          this.tooptip = "请输入用户名";
          this.tag_state = true;
        }
      } else {
        callback();
      }
    };
    return {
      systemVersion: "v 1.1.2",
      loginForm: {
        username: "",
        password: "",
      },
      checked: true,
      loading: false,
      tooptip: "",
      tag_state: true,
      rules: {
        password: [
          { validator: validatePassword, trigger: ["blur", "change"] },
        ],
      },
      pageState: 0,
      isElectron: false,
      isDog: false,
      loginMode: "account_password",
      num: 0,
      sceneOption: [
        { label: '默认', value: 0 }
      ],
      sceneValue: 0,
      scenePath: '',
    };
  },

  methods: {
    ...mapActions("user", ["Login"]),
    async handleLogin () {
      if (this.checked) {
        this.$refs["login_form"].validate(async (validate) => {
          if (validate) {
            this.loading = true;

            this.Login(this.loginForm)
              .then((res) => {

                // console.log(res, 'tokennnnnnnnnnnnnn');

                this.$el_message(
                  "登录成功",
                  () => {
                    this.$router.replace("/").catch(() => { });
                  },
                  "success",
                  1000
                );
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
      } else {
        setTimeout(() => {
          this.$message({
            type: "warning",
            message: "请阅读协议！",
            duration: 1000,
          });
        }, 2000); // 一秒内不能重复点击
        // this.$message({
        //   type: "warning",
        //   message: "请阅读协议！",
        //   duration: 1000,
        // })
      }
    },

    changeState (state) {
      this.pageState = state;
    },
    getBrowserIsElctron () {
      const userAgent = navigator.userAgent;
      const isElectron = userAgent.includes("Electron");
      this.isElectron = isElectron;
    },
    getOldDogKey () {
      try {
        const dllPath =
          process.env.NODE_ENV == "development"
            ? "electron/dog/Dog.dll"
            : "resources/electron/dog/Dog.dll";
        const ffi = require("ffi-napi");
        const customlibProp = ffi.Library(dllPath, {
          mmc_dog_login: ["int64", []],
          mmc_dog_read: ["char *", []],
        });

        // console.log(customlibProp.mmc_dog_login());

        const uint8ArrayBuffer = customlibProp.mmc_dog_read();
        const uint8Array = new Uint8Array(uint8ArrayBuffer.buffer);
        const newUint8Arry = uint8Array.slice(0, 128);

        let uint8String = new TextDecoder().decode(newUint8Arry);
        uint8String = uint8String.replace(/\x00/g, "");
        // console.log('旧加密狗：token-', uint8String)

        if (uint8String && uint8String.length > 0) {
          this.dogKey = uint8String;
          this.isDog = true;
        } else {
          this.isDog = false;
        }
      } catch (error) {
        this.isDog = false;
      }
    },
    getNewDogKey () {
      if (!ipcRenderer) {
        return;
      }
      ipcRenderer.send("onFindPort");

      ipcRenderer.on("ErrMsg", function (event, ErrMsg) {
        // console.log('新加密狗：', ErrMsg)
      });

      ipcRenderer.on("ErrMsgEx", function (event, ErrMsg, lasterror) {
        // console.log('新加密狗：', ErrMsg + lasterror.toString())
      });

      ipcRenderer.on("GetUSBKeyInfo", function (event, ID, Ver, VerEx) {
        // console.log('新加密狗：', 'GetUSBKeyInfo')
        // console.log(event, ID, Ver, VerEx)
        if (ID) {
          ipcRenderer.send("OnYReadStringBnt");
        }
      });

      ipcRenderer.on("Msg", function (event, Msg) {
        // console.log('新加密狗：', Msg)
      });
      ipcRenderer.on("TokenMsg", (event, Msg) => {
        // console.log('新加密狗：token-', Msg)
        // alert(Msg)
        if (Msg && Msg.length > 0) {
          this.dogKey = Msg;
          this.isDog = true;
        } else {
          this.isDog = false;
        }
      });
    },
    // 读取场景配置文件夹下面的场景文件名
    getSceneName () {
      // 这种方法，打包之后，修改无效（文件夹添加或者删除，不会被发现）
      // const files = require.context("../../../public/config/sceneConfig", true, /\.json$/).keys();
      // console.log(files, '配置文件列表');
      // files.forEach((item, index) => {
      //   let a = item.substr(2).substr(0, item.substr(2).length - 5);
      //   this.sceneOption.push({ label: a, value: index + 1 });
      // });
      // 直接引入js，在js中配置，成功
      for (let i = 0; i < window.sceneConfig.length; i++) {
        this.sceneOption.push({ label: window.sceneConfig[i], value: i + 1 });
      }
    },
    // 读取不同的场景数据
    async readConfig (index) {
      // console.log(index, 'indexxxxxxxxxxxxxxxxxxxxxxxxxx');
      if (index == 0) { //默认场景
        window.localStorage.setItem('sceneConfigData', "{}");
        window.sceneConfigData = undefined;
      } else {
        if (!this.sceneOption[index]) return;
        this.scenePath = `config/sceneConfig/${this.sceneOption[index]['label']}.json`;
        let r = await axios.get(this.scenePath);
        window.localStorage.setItem('sceneConfigData', JSON.stringify(r.data));
        // // 登录的时候将场景数据添加到localStore中，在此处获取到localStore中的场景数据
        // let b = window.localStorage.getItem('sceneConfigData');
        window.sceneConfigData = r.data;
      }
    },
    // 选择场景
    sceneChange (v) {
      this.readConfig(v);
    }
  },
  mounted () {
    this.getSceneName();
    // 默认场景
    this.readConfig(0);
  },
  created () {
    const _this = this;
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13) {
        _this.handleLogin();
      }
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .cpt-index {
    font-family: Microsoft YaHei;
    width: 100%;
    height: 100%;
    background: url("~@/assets/loginImage/BG.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-left {
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;

  .title {
    font-size: 42px;
    font-weight: 600;
    line-height: 84px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .sub-title {
    font-size: 24px;
    line-height: 42px;
    letter-spacing: 15px;
    font-weight: 400;
  }

  img {
    width: 47.1vw;
    height: 60.4vh;
    margin-top: 14px;
  }
}

@media screen and (min-width: 1920px) {
  .login-box {
    width: 20vw;
  }
}

@media screen and (max-width: 1919px) {
  .login-box {
    width: 24vw;
  }
}

.login-box {
  // width: 24vw;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-left: 71px;
  padding: 40px 40px 10px 40px;
  justify-content: space-between;

  .login_form_head {
    display: flex;
    flex-direction: column;

    .login-logo {
      width: 6.25vw;
      height: 2.7vh;
      margin-bottom: 20px;
    }

    .title {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 40px;
    }

    .sub-title {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4f4f4f;
      line-height: 32px;
    }
  }

  .form-box {
    width: 100%;

    // border: 1px solid;
    .login-form {
      display: flex;
      flex-direction: column;
      margin: 0px auto;

      .form-lable {
        margin-bottom: 0px;
      }

      .icon-denglu {
        background: #3c4049;
      }

      .icon-pwd {
        background: #0b111f;
      }

      .el-form-item {
        margin: 15px 0;
      }

      .login-username {
        .icon-a-mima {
          color: #1eb9fa;
        }

        .el-input__inner {
          height: 48px;
          border: none;
          padding-left: 50px;
          // background: #3c4049;
          background: #0b111f;
          border-radius: 0px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;

          &::placeholder {
            opacity: 0.5;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #badfee;
            font-weight: 400;
          }
        }
      }

      .login-password {
        .el-input__inner {
          height: 48px;
          border: none;
          padding-left: 50px;
          background: #0b111f;
          border-radius: 0px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          &::placeholder {
            opacity: 0.5;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #badfee;
            font-weight: 400;
          }
        }

        .el-input__icon {
          height: 48px;
          line-height: 48px;
        }
      }

      .login-tag {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        text-align: left;
        height: 20px;
        line-height: 20px;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .tag-color-true {
        color: red;
      }

      .tag-color-false {
        color: #333333;
      }

      .login-check {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        flex-direction: row;

        .el-checkbox {
          margin-right: 10px;
          font-family: MicrosoftYaHei;
          margin-bottom: 20px;
          margin-right: 5px;
        }

        .xieyi {
          color: #2b72ea;
          text-align: center;
          font-weight: 400;
          cursor: pointer;
        }
      }

      .el-form-item {
        border: none;
        padding: 0px;
        margin-bottom: 6px;
      }

      // input框的前置图标大小
      .el-input__prefix {
        width: 48px;
        color: #1eb9fa;
      }

      .iconfont {
        font-size: 24px;
        width: 48px;
        height: 45px;
        padding-top: 3px;
        margin-left: -3px;
      }

      //登录按钮
      .login_button {
        background: #007aff;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ffffff;
        font-weight: 400;
        height: 48px;
        margin-top: 6px;
        border-radius: 4px;
        width: 100%;
      }

      .version {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #898a8e;
        line-height: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
    }
  }
}
</style>
<style>
.scene-select {
  .el-select-dropdown__wrap {
    background-color: #ffffff !important;
  }

  .el-select-dropdown__item.hover {
    background-color: #ffffff !important;
  }

  .popper__arrow {
    display: none;
  }

  .el-scrollbar {
    border: 0;
  }

  .el-scrollbar {
    background-color: #ffffff;
  }
}
</style>