<template>
  <div v-show="pageState == 1" class="user-agreement">
    <div v-interact class="header">
      <div class="head">
        <span class="logo" />
        <span class="title">联飞智能空域指挥控制系统</span>
      </div>
      <span class="iconfont icon-guanbi" @click="changeState" />
    </div>
    <div class="body">
      <div class="title">联合飞机服务协议</div>
      <ul>
        <li>
          <p>欢迎您使用联合飞机的服务！</p>
        </li>
        <li>
          <p>
            为使用联合飞机的服务，您应当阅读并遵守《联合飞机服务协议》(以下简称“本协议”)。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款，以及开通或使用某项服务的单独协议。限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。除非您已阅读并接受本协议所有条款，否则您无权使用联合飞机提供的服务。您使用联合飞机的服务即视为您已阅读并同意上述协议的约束。
          </p>
        </li>
        <li>
          <p>
            如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
          </p>
        </li>
      </ul>
      <ul>
        <li>一、协议的范围</li>
        <li>
          <p>
            1.1本协议是您与联合飞机之间关于用户使用联合飞机相关服务所订立的协议。“联合飞机”是指浙江联合飞机创新科技有限公司及其相关服务可能存在的运营关联单位。“用户”是指在中国使用联合飞机服务的使用人，在本协议中更多地称为“您”。
          </p>
        </li>
        <li>
          <p>
            1.2本协议项下的服务是指联合飞机向用户提供的包括但不限于即时通讯、互联网增值、互动娱乐、电子商务和广告等产品及服务(以下简称“本服务”)。
          </p>
        </li>
        <li>
          <p>
            1.3本协议内容同时包括《隐私政策》,且您在使用联合飞机某一特定服务时，该服务可能会另有单独的协议、相关业务规则等(以下统称为“单独协议”)。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。　　
          </p>
        </li>
      </ul>
      <ul>
        <li>二、账号与密码安全</li>
        <li>
          <p>
            2.1您在使用联合飞机的服务时可能需要注册一个账号。关于您使用账号的具体规则，请遵守单独协议。
          </p>
        </li>
        <li>
          <p>
            2.2联合飞机特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。
          </p>
        </li>
      </ul>
      <ul>
        <li>三、用户个人信息保护</li>
        <li>
          <p>
            3.1联合飞机保护用户个人信息是联合飞机的一项基本原则。联合飞机将按照本协议及《隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《隐私政策》的内容为准。
          </p>
        </li>
        <li>
          <p>
            3.2您在注册账号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份、公司注册信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。　
          </p>
        </li>
        <li>
          <p>
            3.3一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别(如号码申诉服务)的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
          </p>
        </li>
        <li>
          <p>
            3.4联合飞机将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
          </p>
        </li>
        <li>
          <p>
            3.5联合飞机不会将您的个人信息转移或披露给任何非关联的第三方，除非：
          </p>
        </li>
        <li>
          <p>(1)相关法律法规或法院、政府机关要求；</p>
        </li>
        <li>
          <p>(2)为完成合并、分立、收购或资产转让而转移；或</p>
        </li>
        <li>
          <p>(3)为提供您要求的服务所必需。</p>
        </li>
        <li>
          <p>
            3.6联合飞机非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用联合飞机的服务前，应事先取得您家长或法定监护人(以下简称"监护人")的书面同意。
          </p>
        </li>
        <li>
          <p>
            3.7您同意，联合飞机公司拥有通过邮件、短信、电话等形式，向在本站注册、购买产品或服务的用户发送订单信息等告知信息的权利。
          </p>
        </li>
        <li>
          <p>
            3.8您不得将在联合飞机公司注册获得的账户借给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。
          </p>
        </li>
      </ul>
      <ul>
        <li>四、使用本服务的方式</li>
        <li>
          <p>
            4.1除非您与联合飞机另有约定，您同意本服务仅为您个人非商业性质的使用。
          </p>
        </li>
        <li>
          <p>
            4.2您应当通过联合飞机提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。
          </p>
        </li>
        <li>
          <p>
            4.3您不得使用未经联合飞机授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
          </p>
        </li>
      </ul>
      <ul>
        <li>五、按现状提供服务</li>
        <li>
          <p>
            您理解并同意，联合飞机的服务是按照现有技术和条件所能达到的现状提供的。联合飞机会尽最大努力向您提供服务，确保服务的连贯性和安全性；但联合飞机不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
          </p>
        </li>
      </ul>
      <ul>
        <li>六、自备设备</li>
        <li>
          <p>
            6.1您应当理解，您使用联合飞机的服务需自行准备与相关服务有关的终端设备(如电脑、调制解调器等装置)，并承担所需的费用(如电话费、上网费等费用)。
          </p>
        </li>
        <li>
          <p>6.2您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。</p>
        </li>
      </ul>
      <ul>
        <li>七、广告</li>
        <li>
          <p>
            7.1您同意联合飞机可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息(包括商业与非商业信息)，其方式和范围可不经向您特别通知而变更。
          </p>
        </li>
        <li>
          <p>
            7.2联合飞机可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或联合飞机未书面许可的方式屏蔽、过滤广告信息。
          </p>
        </li>
        <li>
          <p>
            7.3联合飞机依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，联合飞机不承担责任。
          </p>
        </li>
        <li>
          <p>
            7.4您同意，对联合飞机服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
          </p>
        </li>
      </ul>
      <ul>
        <li>八、收费服务</li>
        <li>
          <p>
            8.1联合飞机的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。
          </p>
        </li>
        <li>
          <p>
            8.2联合飞机可能根据实际需要对收费服务的收费标准、方式进行修改和变更，联合飞机也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，联合飞机将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
          </p>
        </li>
      </ul>
      <ul>
        <li>九、第三方提供的产品或服务以及第三方链接</li>
        <li>
          <p>
            9.1您在联合飞机平台上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。联合飞机和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。为方便您的使用，联合飞机平台可能包含链接至第三方网站的情形，联合飞机对第三方网站的任何内容均不承担责任。在使用第三方网站时，您可能需要查阅和接受其使用条款。此外，第三方链接并不意味联合飞机对该网站任何产品或服务的认可。
          </p>
        </li>
        <li>
          <p>
            9.2您特此同意，就您在使用联合飞机网站、网络产品或服务过程中向联合飞机提供、发布或上传的或者以任何形式在通过联合飞机的网络产品或服务进行发布、传播的文字、资讯、资料、软件、声音、图片、录像、图表、广告中的全部内容或其他资料(以下简称“发布内容”)，您将免费地、非专有地、永久且不可撤销地许可联合飞机在全世界范围内自主使用该等发布内容的复制权、发行权、表演权、放映权、广告播权、信息网络传播权、翻译权和汇编权等。同时，您对其他用户发布或上传的发布内容，未经上传或发布者以及其他相关权利人同意，不得擅自复制、发布、转载、播放、改编、汇编或以其他方式使用这些内容或将该等内容用于其他任何商业目的。
          </p>
        </li>
      </ul>
      <ul>
        <li>十、数据的储存</li>
        <li>
          <p>
            10.1
            联合飞机不对您在本服务中相关数据的删除或储存失败负责，您应对重要数据在本服务之外自行进行备份。
          </p>
        </li>
        <li>
          <p>
            10.2
            联合飞机有权根据实际情况自行决定在服务器上对您在本服务中的数据的最长储存期限、为单个用户分配的最大存储使用空间等。您可根据自己的需要自行备份本服务中的相关数据。
          </p>
        </li>
        <li>
          <p>
            10.3
            如果您的服务被终止或取消，联合飞机可以从服务器上永久地删除您的数据。服务终止或取消后，联合飞机没有义务向您返还或恢复任何数据。
          </p>
        </li>
        <li>
          <p>
            10.4
            联合飞机特此郑重提请您注意，任何经由联合飞机的网络产品或服务而发布、上传的文字、资讯、资料、音乐、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由该等发布内容的最初提供者承担责任。联合飞机仅为用户提供内容存储空间，无法控制经由产品和服务传送之内容，因此不保证内容的正确性、完整性或品质。您已预知使用网络产品或服务时，可能会接触到令人不快、不适当或令人厌恶之内容。在任何情况下，联合飞机均不为任何内容负责，但联合飞机有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停包括您在内的任何用户使用网络产品或服务的全部或部分，保存有关记录，并向有权机关报告。
          </p>
        </li>
      </ul>
      <ul>
        <li>十一、基于软件提供服务</li>
        <li>
          <p>若联合飞机依托“软件”向您提供服务，您还应遵守以下约定</p>
        </li>
        <li>
          <p>
            1.1您在使用本服务的过程中可能需要下载软件，对于这些软件，联合飞机给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用本服务的目的而使用这些软件。
          </p>
        </li>
        <li>
          <p>
            11.2为了改善用户体验、保证服务的安全性及产品功能的一致性，联合飞机可能会对软件进行更新。您应该将相关软件更新到最新版本，否则联合飞机并不保证其能正常使用。
          </p>
        </li>
        <li>
          <p>
            11.3联合飞机可能为不同的终端设备开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。您可以直接从联合飞机的网站上获取软件，也可以从得到联合飞机授权的第三方获取。如果您从未经联合飞机授权的第三方获取软件或与软件名称相同的安装程序，联合飞机无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
          </p>
        </li>
        <li>
          <p>11.4除非获得联合飞机书面许可，否则您不得从事下列任一行为：</p>
        </li>
        <li>
          <p>(1)删除软件及其副本上关于著作权的信息；</p>
        </li>
        <li>
          <p>
            (2)对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；
          </p>
        </li>
        <li>
          <p>
            (3)对联合飞机拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
          </p>
        </li>
        <li>
          <p>
            (4)对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经联合飞机授权的第三方工具/服务接入软件和相关系统；
          </p>
        </li>
        <li>
          <p>
            (5)通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
          </p>
        </li>
        <li>
          <p>
            (6)通过非联合飞机开发、授权的第三方软件、插件、外挂、系统，登录或使用联合飞机软件及服务，或制作、发布、传播非联合飞机开发、授权的第三方软件、插件、外挂、系统。
          </p>
        </li>
      </ul>
      <ul>
        <li>十二、知识产权声明</li>
        <li>
          <p>
            12.1联合飞机在本服务中提供的内容(包括但不限于网页、文字、图片、音频、视频、图表等)的知识产权归联合飞机所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。
          </p>
        </li>
        <li>
          <p>
            12.2除另有特别声明外，联合飞机提供本服务时所依托软件的著作权、专利权及其他知识产权均归联合飞机所有。
          </p>
        </li>
        <li>
          <p>
            12.3联合飞机在本服务中所使用的“ZHZ联合飞机”、“联合飞机航空”、“ZHZ”等商业标识，其著作权或商标权归联合飞机所有。
          </p>
        </li>
        <li>
          <p>
            12.4上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经联合飞机、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
          </p>
        </li>
      </ul>
      <ul>
        <li>十三、用户违法行为</li>
        <li>
          <p>
            13.1您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
          </p>
        </li>
        <li>
          <p>
            (1)发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
          </p>
        </li>
        <li>
          <p>
            (2)发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
          </p>
        </li>
        <li>
          <p>(3)恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
        </li>
        <li>
          <p>(4)发布、传送、传播广告信息及垃圾信息；</p>
        </li>
        <li>
          <p>(5)其他法律法规禁止的行为。</p>
        </li>
        <li>
          <p>
            13.2如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求联合飞机给予协助。造成损害的，您应依法予以赔偿，联合飞机不承担任何责任。
          </p>
        </li>
        <li>
          <p>
            13.3如果联合飞机发现或收到他人举报您发布的信息违反本条约定，联合飞机有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，联合飞机有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止使用，追究法律责任等措施
          </p>
        </li>
        <li>
          <p>
            13.4您违反本条约定，导致任何第三方损害的，您应当独立承担责任；联合飞机因此遭受损失的，您也应当一并赔偿。
          </p>
        </li>
      </ul>
      <ul>
        <li>十四、遵守当地法律监管</li>
        <li>
          <p>
            14.1您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
          </p>
        </li>
        <li>
          <p>
            14.2您应避免因使用本服务而使联合飞机卷入政治和公共事件，否则联合飞机有权暂停或终止对您的服务。
          </p>
        </li>
      </ul>
      <ul>
        <li>十五、用户发送、传播的内容与第三方投诉处理</li>
        <li>
          <p>
            15.1您通过本服务发送或传播的内容(包括但不限于网页、文字、图片、音频、视频、图表等)均由您自行承担责任。
          </p>
        </li>
        <li>
          <p>
            15.2您发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。
          </p>
        </li>
        <li>
          <p>
            15.3您同意联合飞机可为履行本协议或提供本服务的目的而使用您发送或传播的内容。
          </p>
        </li>
        <li>
          <p>
            15.4如果联合飞机收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意联合飞机有权进行独立判断并采取删除、屏蔽或断开链接等措施。
          </p>
        </li>
        <li>
          <p>
            15.5您使用本服务时不得违反国家法律法规、侵害他人合法权益。您理解并同意，如您被他人投诉侵权或您投诉他人侵权，联合飞机有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给其他争议方或相关部门，以便及时解决投诉纠纷，保护他人合法权益。
          </p>
        </li>
      </ul>
      <ul>
        <li>十六、不可抗力及其他免责事由</li>
        <li>
          <p>
            16.1您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，联合飞机将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失联合飞机在法律允许的范围内免责。
          </p>
        </li>
        <li>
          <p>
            16.2在法律允许的范围内，联合飞机对以下情形导致的服务中断或受阻不承担责任：
          </p>
        </li>
        <li>
          <p>(1)受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
        </li>
        <li>
          <p>(2)用户或联合飞机的电脑软件、系统、硬件和通信线路出现故障；</p>
        </li>
        <li>
          <p>(3)用户操作不当；</p>
        </li>
        <li>
          <p>(4)用户通过非联合飞机授权的方式使用本服务；</p>
        </li>
        <li>
          <p>(5)其他联合飞机无法控制或合理预见的情形。</p>
        </li>
        <li>
          <p>
            16.3您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，联合飞机不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
          </p>
        </li>
        <li>
          <p>
            (1)来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
          </p>
        </li>
        <li>
          <p>
            (2)因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
          </p>
        </li>
        <li>
          <p>(3)其他因网络信息或用户行为引起的风险。</p>
        </li>
        <li>
          <p>
            16.4您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，联合飞机不承担法律责任。
          </p>
        </li>
        <li>
          <p>
            16.5联合飞机依据本协议约定获得处理违法违规内容的权利，该权利不构成联合飞机的义务或承诺，联合飞机不能保证及时发现违法行为或进行相应处理。
          </p>
        </li>
        <li>
          <p>
            16.6在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意联合飞机有关防范诈骗犯罪的提示。
          </p>
        </li>
      </ul>
      <ul>
        <li>十七、协议的生效与变更</li>
        <li>
          <p>
            17.1您使用联合飞机的服务即视为您已阅读本协议并接受本协议的约束。
          </p>
        </li>
        <li>
          <p>
            17.2联合飞机有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
          </p>
        </li>
        <li>
          <p>
            17.3本协议条款变更后，如果您继续使用联合飞机提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用联合飞机提供的软件或服务。
          </p>
        </li>
      </ul>
      <ul>
        <li>十八、服务的变更、中断、终止</li>
        <li>
          <p>
            18.1联合飞机可能会对服务内容进行变更，也可能会中断、中止或终止服务。
          </p>
        </li>
        <li>
          <p>
            18.2您理解并同意，联合飞机有权自主决定经营策略。在联合飞机发生合并、分立、收购、资产转让时，联合飞机可向第三方转让本服务下相关资产；联合飞机也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以联合飞机通知的为准。
          </p>
        </li>
        <li>
          <p>
            18.3如发生下列任何一种情形，联合飞机有权不经通知而中断或终止向您提供的服务：
          </p>
        </li>
        <li>
          <p>
            (1)根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          </p>
        </li>
        <li>
          <p>(2)您违反相关法律法规或本协议的约定；</p>
        </li>
        <li>
          <p>(3)按照法律规定或主管部门的要求；</p>
        </li>
        <li>
          <p>(4)出于安全的原因或其他必要的情形。</p>
        </li>
        <li>
          <p>
            18.4联合飞机有权按本协议8.2条的约定进行收费。若您未按时足额付费，联合飞机有权中断、中止或终止提供服务。
          </p>
        </li>
        <li>
          <p>
            18.5您有责任自行备份存储在本服务中的数据。如果您的服务被终止，联合飞机可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，联合飞机没有义务向您返还数据。
          </p>
        </li>
      </ul>
      <ul>
        <li>十九、外部漏洞报告</li>
        <li>
          <p>
            19.1联合飞机非常重视自身产品和业务系统的信息安全问题，我们希望通过外部报告漏洞处理流程加强与漏洞报告者的合作，以此提升联合飞机产品和业务系统的安全水平。我们感谢每一位报告者反馈的问题。
          </p>
        </li>
        <li>
          <p>
            19.2联合飞机尊重每一位用户的研究成果及漏洞反馈。真诚欢迎每一位用户向联合飞机报告漏洞，我们将按照漏洞质量给予感谢和回馈。
          </p>
        </li>
        <li>
          <p>
            19.3联合飞机将用户利益放在第一位，尽最大努力保护联合飞机用户的利益。
          </p>
        </li>
        <li>
          <p>19.4联合飞机反对并谴责以下行为，并保留依法追究责任的权利：</p>
        </li>
        <li>
          <p>(1)利用漏洞攻击联合飞机的系统，造成系统或数据异常等。</p>
        </li>
        <li>
          <p>
            (2)以漏洞测试为借口，利用漏洞进行破坏，损害用户利益的行为，包括但不限于利用漏洞盗取用户资料、隐私及虚拟财产，或入侵业务系统、窃取数据等。
          </p>
        </li>
        <li>
          <p>
            (3)恶意传播漏洞，或在报告漏洞后、漏洞未修复前，对漏洞进行公开和传播。
          </p>
        </li>
        <li>
          <p>(4)利用安全漏洞进行恐吓、敲诈的行为。</p>
        </li>
        <li>
          <p>(5)有害或者结果不可控的安全测试行为。</p>
        </li>
      </ul>
      <ul>
        <li>二十、管辖与法律适用</li>
        <li>
          <p>
            20.1本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律，不包括香港特别行政区、澳门特别行政区和台湾地区的法律。
          </p>
        </li>
        <li>
          <p>20.2本协议签订地为中华人民共和国广东省深圳市</p>
        </li>
        <li>
          <p>
            20.3若您和联合飞机之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交中国深圳仲裁委员会仲裁，仲裁地点为中国深圳，仲裁使用中文，包括提交的文件及仲裁过程使用的语言。联合飞机有权选择书面仲裁审理，仲裁裁决为终极裁决，对各方均有约束力。
          </p>
        </li>
        <li>
          <p>
            20.4本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
          </p>
        </li>
        <li>
          <p>
            20.5本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
          </p>
        </li>
      </ul>
      <ul>
        <li>二十一、未成年人使用条款</li>
        <li>
          <p>
            21.1若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。
          </p>
        </li>
        <li>
          <p>
            21.2未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
          </p>
        </li>
        <li>
          <p>
            (1)认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
          </p>
        </li>
        <li>
          <p>
            (2)填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
          </p>
        </li>
        <li>
          <p>(3)在监护人或老师的指导下，学习正确使用网络；</p>
        </li>
        <li>
          <p>
            (4)避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
          </p>
        </li>
        <li>
          <p>
            21.3监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。　
          </p>
        </li>
      </ul>
      <ul>
        <li>二十二、其他</li>
        <li>
          <p>
            如您对本协议内容或联合飞机的服务有任何的建议或意见，请通过info@uatair.com与我们联系，我们会给予您必要的帮助
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  props: ["pageState"],

  data () {
    return {
      form: {
        selectTask: "",
      },
      selectTask_options: [],
    };
  },

  mounted () { },

  methods: {
    changeState () {
      this.$emit("changeState", 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-agreement {
  width: 40.9%;
  height: 80%;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  top: 10%;
  left: calc((100% - 40.9%) / 2);
  z-index: 1000;

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    background: #292c35;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.15), inset 0 -1px 0 0 #000000;

    .head {
      height: 32px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 24px;
        height: 24px;
        background: url("~@/assets/loginImage/MSpaceLogo1.png") no-repeat;
        background-size: 100% 100%;
      }

      .title {
        height: 32px;
        line-height: 32px;
        margin-left: 8px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ebf5ff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }

    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .body {
    height: calc(100% - 85px);
    padding: 20px 10px;
    margin: 0 8px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f9ddbc;
    letter-spacing: 1.14px;
    line-height: 19px;
    font-weight: 400;
    background: #11151d;
    border: 1px solid #141619;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      background: #4c5265;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      background-color: #09222d;
    }

    .title {
      font-size: 16px;
      text-align: center;
      font-weight: 700;
    }

    ul {
      text-align: justify;
      line-height: 30px;
      letter-spacing: 1.14px;
      // border: 1px solid;
      margin: 20px 0px;

      li {
        margin: 20px 0px;
      }

      p {
        text-indent: 2em;
      }

      .section-title {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}</style>
